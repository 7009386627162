import dayjs from 'dayjs';
import Category from 'models/Category';
import Location from 'models/Location';
import { chain } from 'lodash';

class Campaign {
  public id: string;
  public accountId: string;
  public brandId: string;
  public name: string;
  public description: string;
  public createdAt: string;
  public startsAt: string;
  public endsAt: string;
  public image: string;
  public video: string;
  public status: string;
  public promoteBrand: boolean;
  public locations: Location[];
  public categories: Category[];
  public finePrint: string[];
  public tags: string[];
  public daterange: dayjs.Dayjs[];

  public constructor(data: any) {
    this.id = data.id || '';
    this.accountId = data.accountId || '';
    this.brandId = data.brandId || '';
    this.name = data.name;
    this.description = data.description;
    this.createdAt = data.createdAt || '';
    this.startsAt = data.startsAt || '';
    this.endsAt = data.endsAt || '';

    if (data.daterange && data.daterange.length > 1) {
      this.startsAt = data.daterange[0].format();
      this.endsAt = data.daterange[1].format();
    }

    this.image = data.image || '';
    this.video = data.video || '';
    this.status = data.status || 'created';
    this.promoteBrand = data.promoteBrand || false;

    if (data.categories && data.categories.length > 0) {
      this.categories = data.categories.map((cat: any) => new Category(cat))
    } else {
      this.categories = [];
    }

    if (data.locations && data.locations.length > 0) {
      try {
        this.locations = data.locations.map((loc: any) => {
          return new Location(loc);
        })
      } catch (e) {
        console.log('error mapping locations:', e);
        this.locations = [];
      }
    } else {
      this.locations = [];
    }

    this.finePrint = data.finePrint || [];
    this.tags = data.tags || [];
    this.daterange = [dayjs(data.startsAt), dayjs(data.endsAt)]
  }

  public hasAssets = () => {
    if (!this.image && !this.video) {
      return false;
    }

    return true;
  }

  public groupCategoryIds = (cat: Category[]) => {
    return chain(this.categories)
      .groupBy('parentId')
      .mapKeys((val, key) => {
        const p = cat.find((c: Category) => c.id === key);
        if (p !== undefined) {
          return p.name.toLowerCase();
        }
        return key;
      })
      .value();
  }

  static fromJsonList = (data: any) => {
    if (data.length === 0) {
      return [];
    }

    return data.map((campaign: any) => {
      return new Campaign(campaign);
    });
  }
}

export default Campaign;
