import { makeAutoObservable, toJS} from 'mobx';
import RootProvider from './RootProvider';
import Api from 'api/api';
import {
  GameRequest,
  GameSettingsRequest,
  PrizeRequest,
  CampaignWizardDefaults
} from 'types';
import {
  CampaignEmptyForm,
  CampaignAssetsEmptyForm,
  GameEmptyForm,
  CategoriesEmptyForm,
  RewardEmptyForm,
  LocationsEmptyForm,
  ExtrasEmptyForm,
} from 'data/formValues';
import Location from 'models/Location';
import Campaign from 'models/Campaign';
import Game from 'models/Game';
import Reward from 'models/Reward';
import Category from 'models/Category';
import WizzardContext from 'models/WizzardContext';
import difficulties from 'data/gameDifficulties';
import businessProvider from "./BusinessProvider";

class CampaignProvider {

  root: RootProvider;
  private brandMap = new Map();
  private prizeStats = new Map();
  private campaignMap = new Map();
  private campaignList: Campaign[] = [];
  private campaignCount = 0;
  private campaignSortable = [];
  private isFetching = false;
  private finePrintList: string[] = [];
  private availableCategories: Category[] = [];
  private _brandLocations: Location[] = [];
  private _items: any[] = [];
  private _reward?: Reward;
  private _locationCatalogs: Map<string, any[]> = new Map();
  private _editingCampaign: boolean = false;

  public steps = [
    'campaign',
    'assets',
    'categories',
    'game',
    'locations',
    'reward',
    'print',
    'success',
    '_items',
    //'extras'
  ];

  public ctx: WizzardContext = new WizzardContext();

  public defaults: CampaignWizardDefaults = {
    campaign: {
      defaultValues: CampaignEmptyForm,
    },
    assets: {
      defaultValues: CampaignAssetsEmptyForm,
    },
    categories: {
      defaultValues: CategoriesEmptyForm,
    },
    game: {
      defaultValues: GameEmptyForm,
    },
    reward: {
      defaultValues: RewardEmptyForm,
    },
    locations: {
      defaultValues: LocationsEmptyForm,
    },
    extras: {
      defaultValues: ExtrasEmptyForm,
    },
    _items: {
      defaultValues: [],
    },
  }


  public setDefaults = (key: string, value: any) => {
    this.defaults[key].defaultValues = value;
  }

  public currentStep = -1;

  public setCurrentStep = (step: number) => {
    this.currentStep = step;
  }

  public stepDown = () => {
    if (this.currentStep > -1) {
      this.currentStep--;
    }
  }

  public stepUp = () => {
    if (this.currentStep < this.steps.length - 1) {
      this.currentStep++;
    }
  }

  public get categories() {
    return toJS(this.availableCategories);
  }

  public get reward() {
    return toJS(this._reward);
  }

  public get fetching() {
    return this.isFetching;
  }

  public get finePrint() {
    return this.finePrintList;
  }

  public get brandLocations() {
    return this._brandLocations;
  }

  public get locationCatalogs() {
    return this._locationCatalogs;
  }

  public setFinePrint = (finePrint: string[]) => {
    this.finePrintList = finePrint;
  }

  private setFetching(fetching: boolean) {
    this.isFetching = fetching;
    //this.root.drawerProvider.setFetching(fetching);
  }

  private setSaving(fetching: boolean) {
    this.root.drawerProvider.setFetching(fetching);
  }

  private setCampaigns(data: any) {
    this.campaignList = Campaign.fromJsonList(data.data);
    this.campaignCount = data.count;
    this.campaignSortable = data.sortable;

    this.campaignList?.forEach((campaign: Campaign) => {
      this.campaignMap.set(campaign.id, campaign);
    });
  }

  private setAvailableCategories(categories: Category[]) {
    this.availableCategories = categories;
  }

  public startNewCampaign = () => {
    this.setDefaults('campaign', CampaignEmptyForm);
    this.resetContext();

    this._editingCampaign = true;
  }

  public stopEditingCampaign = () => {
    this._editingCampaign = false;
    this.getCampaigns();
  }

  public setEditingCampaign(campaign: Campaign) {
    this.resetContext();

    this.ctx.setCampaign(campaign);
    this.ctx.setEditing(true);

    this._editingCampaign = true;
  }

  public setGameImage = (file: File) => {
    this.ctx.setGameImage(file);
  }

  public resetContext = () => {
    this.ctx.reset();
  }

  public get campaigns() {
    return toJS(this.campaignList)
  }

  public getCampaign(id: any) {
    return toJS(this.campaignMap.get(id));
  }

  public get brands() {
    return this.brandMap;
  }

  public get locations() {
    return this._brandLocations;
  }

  public get stats() {
    return this.prizeStats;
  }

  public get items(){
    return this._items;
  }

  public get editingCampaign() {
    return this._editingCampaign;
  }

  /*
   * getCampaigns will call service to get list of all campaigns
   * if user is owner if will get campaigns for specific account
   * otherwise if admin it will get all campaigns
  */
  public getCampaigns = async () => {
    this.setFetching(true);
    const business = this.root.businessProvider.business;

    try {
      let response;
      if (business?.id) {
        response = await Api.campaign.getAccountCampaigns(business.id);
        await this.root.businessProvider.getBrands();
      } else {
        //@TODO - pagination
        response = await Api.campaign.getCampaigns();
        const ids = response.data.map((c: any) => c.brand);
        await this.root.businessProvider.getBrands(ids);
      }
      this.createBrandMap();
      await this.createStats(response.data);

      this.setCampaigns(response);
      this.setFetching(false);
    } catch (e: any | Error) {
      this.root.errorProvider.checkApiError(e);
      this.setFetching(false);
    }
  }

  public getItems = async (s: any) => {
    try {
      let response;
      console.log('this.locations', toJS(this.locations));
      response = await Api.catalog.getItems(s, this.locations[0].id);
      console.log('getItems response', response.data);
      // add locationId to items, because it's needed in create prize request
      this._items = response.data.map((item: any) => ({ ...item, locationId: this.locations[0].id }));
    } catch (e: any | Error) {
      this.root.errorProvider.checkApiError(e);
    }
  }

  public getMappings = async (name: string, locationIds: Array<string>): Promise<Array<any> | undefined> => {
    try {
      const response = await Api.catalog.getMappings(name, locationIds);
      return response.data; // Return the data here
    } catch (e: any | Error) {
      this.root.errorProvider.checkApiError(e);
      return undefined;
    }
  }


  private createBrandMap = () => {
    this.root.businessProvider.brands &&
    this.root.businessProvider.brands.forEach(brand => {
      this.brandMap.set(brand.id, brand);
    });
  }

  private createStats = async (campaigns: any[]) => {
    const ids = campaigns.map((c: any) => c.id);
    const prizes = await Promise.all(ids.map(i => Api.prize.getPrizesForCampaign(i).then(res => res.data)));
    const stats = await Promise.all(prizes.flat().map(p => Api.prize.getPrizeStats(p.id)));

    prizes.flat().forEach(p => {
      const stat = stats.find(s => s.id === p.id);
      this.prizeStats.set(p.campaignId, stat);
    });
  }

  public saveCampaign = async (data: any) => {
    this.setFetching(true);

    const campaign = new Campaign(data);
    const business = this.root.businessProvider.business;
    try {
      if (business?.id && !this.ctx.isEditing) {
        const res = await Api.campaign.createForAccount(business.id, campaign);
        const c = new Campaign(res.data);
        this.ctx.setCampaign(c);
      } else {
        const res = await Api.campaign.update(campaign.id, campaign);
        const c = new Campaign(res.data);
        this.ctx.setCampaign(c);
        this.ctx.updateCampaign(this.campaigns);
      }
      this.setCurrentStep(1);
      this.setFetching(false);
    } catch (e: Error | any) {
      this.setFetching(false);
      this.root.errorProvider.checkApiError(e);
    }
  }

  public saveCampaignAssets = async (data: any) => {
    this.setFetching(true);

    if (!this.ctx.campaign.hasAssets() && !this.ctx.hasCampaignImage() && !this.ctx.hasCampaignVideo()) {
      console.log('no image or video');
      return;
    }

    try {
      let imageUpload;
      let videoUpload;

      if (this.ctx.hasCampaignImage()) {
        imageUpload = await Api.document.uploadImage(this.ctx.campaignImage!);
        this.ctx.campaign.image = imageUpload.data.original;
      }

      if (this.ctx.hasCampaignVideo()) {
        videoUpload = await Api.document.uploadVideo(this.ctx.campaignVideo!);
        this.ctx.campaign.video = videoUpload.data.url;
      }

      await Api.campaign.update(this.ctx.campaign.id, this.ctx.campaign);
      this.stepUp();
      this.setFetching(false);
    } catch (e: Error | any) {
      this.setFetching(false);
      this.root.errorProvider.checkApiError(e);
    }
  }

  public getAllCategories = async (): Promise<any> => {
    let result: any = undefined;
    this.setFetching(true);

    try {
      const res = await Api.campaign.getCategories();
      const categories = Category.fromJsonList(res.data);

      if (this.ctx.campaign) {
        const cats = this.ctx.campaign.groupCategoryIds(categories);
        this.setDefaults('categories', cats);
        result = cats;
      }

      this.setAvailableCategories(categories);
      this.setFetching(false);
    } catch (e: Error | any) {
      this.setFetching(false);
      this.root.errorProvider.checkApiError(e);
    }

    return result;
  }

  public saveCategories = async (data: any) => {
    // @TODO - if no categories selected...skip and continue
    const cuisineIds = data.cuisines?.map((c: any) => c.id) || []
    const dietIds = data.diets?.map((d: any) => d.id) || [];
    const ids = [...cuisineIds, ...dietIds];
    const newCategoriesList = [...data.cuisines, ...data.diets];

    this.setFetching(true);
    try {
      await Api.campaign.saveCategories(this.ctx.campaign.id, ids);
      this.stepUp();
      this.setFetching(false);

      //Update campaign categories
      const idx = this.campaigns.findIndex((obj) => obj.id = this.ctx.campaign.id);
      this.campaigns[idx].categories = newCategoriesList;

    } catch (e: Error | any) {
      this.setFetching(false);
      this.root.errorProvider.checkApiError(e);
    }
  }

  public saveGame = async (data: any) => {
    const difficulty = Game.difficultyByLabel(data.difficulty);

    this.setFetching(true);
    const business = this.root.businessProvider.business;
    try {

      //create game settings object
      const settings = {
        duration: difficulty!.duration,
        pieces: difficulty!.pieces,
        rotation: false,
      } as GameSettingsRequest;

      if (this.ctx.gameImage) {
        const upload = await Api.document.uploadImage(this.ctx.gameImage!);
        settings.image = upload.data.original;
      }

      const payload = {
        campaignId: this.ctx.campaign.id,
        type: 'puzzle',
        settings
      }

      console.log('ctx.game', toJS(this.ctx.game));
      console.log('ctx.campaign', toJS(this.ctx.campaign));
      console.log('business', toJS(business));

      if (this.ctx.game.id) {
        // update Game with all changes
        const resUpdate = await Api.game.updateGame(this.ctx.game.id, payload);
        this.ctx.setGame(new Game(resUpdate.data));
      } else {
        const res = await Api.game.createGame(business!.id, payload as GameRequest)
        this.ctx.setGame(new Game(res.data));
      }

      this.setFetching(false);
      this.stepUp();
    } catch (e: Error | any) {
      this.setFetching(false);
      this.root.errorProvider.checkApiError(e);
    }
  }

  public getGameForCampaign = async () => {
    this.setFetching(true);
    const business = this.root.businessProvider.business;

    try {
      if (business?.id) {
        const res = await Api.game.getForCampaign(business.id, this.ctx.campaign.id);
        if (res.count > 0) {
          const game = new Game(res.data[0]);
          this.ctx.setGame(game);
          const difficulty = difficulties.find((d) => d.id === `${game.settings!.pieces}x${game.settings!.duration}`)

          if (difficulty) {
            this.setDefaults('game', { difficulty: difficulty.label });
          }
        }
      }
      this.setFetching(false);
    } catch (e: Error | any) {
      this.setFetching(false);
      this.root.errorProvider.checkApiError(e);
    }
  }

  private getMappedLocationIds = async (itemNames: string[], locationIds: string[]) => {
    console.log('locationIds', locationIds);
    var mappedLocationIds: string[] = [];

    for (const name of itemNames) {
      console.log('getting mapping for name', name);
      const mappingsForName = await this.getMappings(name, locationIds);
      const locationsIdsForName = mappingsForName?.map((m: any) => m.locationId) || [];
      mappedLocationIds = [...mappedLocationIds, ...locationsIdsForName];
    }

    console.log('mappedLocationIds', mappedLocationIds);

    return mappedLocationIds;
  }

  public saveReward = async (data: any) => {
    this.setFetching(true);
    const business = this.root.businessProvider.business;

    const campaign = this.ctx.campaign;
    data.campaignId = campaign.id;
    data.gameId = this.ctx.game.id;
    data.brandId = this.ctx.campaign?.brandId;

    if (data.duration && typeof data.duration === 'string') {
     try {
       data.duration = parseInt(data.duration);
     } catch (e) {
       console.log('error parsing duration', e);
     }
    }

    console.log('this.ctx.campaign.brandId', toJS(this.ctx.campaign?.brandId));
    const prize = this.prizeStats.get(campaign.id);

    if (this.ctx.reward.type === 'free_item'){
      const itemNames = data.freeItems?.map((item: any) => item.name);
      const locationIds = this.locations.map((location: any) => location.id);
      const mappedLocationIds = await this.getMappedLocationIds(itemNames, locationIds);

      const missingLocationIds = locationIds.filter((id: string) => !(mappedLocationIds.includes(id)));

      if (missingLocationIds.length > 0) {
        const filteredLocations = this.locations.filter((location: any) => mappedLocationIds.includes(location.id));
        await this.updateLocations(filteredLocations);
      }
    }

    try {
      console.log('ctx.reward', toJS(this.ctx.reward));
      console.log('this reward', toJS(this.reward));
      if (business?.id && !prize) {
        await Api.prize.createPrize(business.id, data as PrizeRequest);
        await this.createStats([campaign]);
      } else {
        await Api.prize.updatePrize(this.ctx.reward.id, data);
      }

      this.stepUp();
      this.setFetching(false);
    } catch (e: Error | any) {
      this.setFetching(false);
      this.root.errorProvider.checkApiError(e);
    }
  }

  public getRewardForCurrentCampaign = async () => {
    return await this.getRewardForCampaign(this.ctx.campaign.id);
  }

  public getRewardForCampaign = async (id: any) => {
    this.setFetching(true);

    try {
      const res = await Api.prize.getPrizesForCampaign(id);

      let reward = undefined;
      if (res.data.length > 0) {
        reward = new Reward(res.data[0]);
        this.ctx.setReward(reward);
        this._reward = reward;

        this.setDefaults('reward', {
          name: reward.name,
          type: reward.type,
          amount: reward.amount?.value,
          percent: reward.percent?.value,
          minOrderAmount: reward.minOrderAmount?.value
        });
      }

      this.setFetching(false);
      return reward;
    } catch (e: Error | any) {
      this.setFetching(false);
      this.root.errorProvider.checkApiError(e);
    }
  }

  public getBrandLocations = async () => {
    this.setFetching(true);
    const business = this.root.businessProvider.business;

    try {
      if (business?.id) {
        const res = await Api.business.locationsForAccount(business.id, this.ctx.campaign.brandId);
        if (res.data.length > 0) {
          this._brandLocations = Location.fromJsonList(res.data);
        }
      }

      this.setFetching(false);
    } catch (e: Error | any) {
      this.setFetching(false);
      this.root.errorProvider.checkApiError(e);
    }
  }

  public getLocationCatalogs = async (brandLocations: any[]) => {
    this.setFetching(true);

    const fetchCatalogs = brandLocations.map(async (location) => {
      const res = await Api.catalog.getLocationCatalogs(location.id);
      if (res.data.length > 0) {
        this._locationCatalogs.set(location.id, res.data);
      }
    });

    try {
      await Promise.all(fetchCatalogs);
    } catch (e: Error | any) {
      this.root.errorProvider.checkApiError(e);
    } finally {
      this.setFetching(false);
    }
  }

  // Adds locations to the campaign
  public saveLocations = async (data: any) => {
    const locations = this._brandLocations
      .filter((loc) => data.locations[loc.id])
      .map((loc) => {
        const {id, longitude, latitude} = loc;
        return {id, longitude, latitude};
      });

    this.setFetching(true);
    try {
      const res = await Api.campaign.saveCampaignLocations(this.ctx.campaign.id, locations);
      const c = new Campaign(res.data);
      this.ctx.setCampaign(c);

      //Update campaign locations
      const idx = this.campaigns.findIndex((obj) => obj.id = this.ctx.campaign.id);
      this.campaigns[idx].locations = res.data.locations;

      this.setFetching(false);
      this.stepUp();
    } catch (e: Error | any) {
      this.setFetching(false);
      this.root.errorProvider.checkApiError(e);
    }
  }

  public updateLocations = async (locations: Location[]) => {
    try {
      const res = await Api.campaign.saveCampaignLocations(this.ctx.campaign.id, locations);
      const c = new Campaign(res.data);
      this.ctx.setCampaign(c);

      //Update campaign locations
      const idx = this.campaigns.findIndex((obj) => obj.id = this.ctx.campaign.id);
      this.campaigns[idx].locations = res.data.locations;

      this.setFetching(false);
      this.stepUp();
    } catch (e: Error | any) {
      this.setFetching(false);
      this.root.errorProvider.checkApiError(e);
    }
  }

  public addFinePrint = (value: string) => {
    this.finePrintList = [...this.finePrintList, value];
  }


  public removeFinePrint = (value: string) => () => {
    const filtered = this.finePrintList.filter(fp => fp !== value);
    this.setFinePrint(filtered);
  }

  public saveFinePrint = async () => {
    console.log('fine print campaign', this.ctx.campaign);
    this.setFetching(true);
    this.ctx.campaign.finePrint = this.finePrint;

    try {
      const res = await Api.campaign.update(this.ctx.campaign.id, this.ctx.campaign);
      const c = new Campaign(res.data);
      this.ctx.setCampaign(c);

      //Update campaign fineprint
      const idx = this.campaigns.findIndex((obj) => obj.id = this.ctx.campaign.id);
      this.campaigns[idx].finePrint = res.data.finePrint;

      this.setFetching(false);
      this.stepUp();
    } catch (e: Error | any) {
      this.setFetching(false);
      this.root.errorProvider.checkApiError(e);
    }
  }

  public saveExtras = () => {
    this.stepUp();
  }

  public finish = () => {
    this.setCurrentStep(-1);
  }

  constructor(root: RootProvider) {
    this.root = root;
    makeAutoObservable(this, {}, { autoBind: true });
  }
}

export default CampaignProvider;

